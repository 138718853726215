import { Component } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';

import '../../assets/css/perfil.css';

import Profile from "../../assets/img/profile.png"

class Perfil extends Component{
    constructor(props){
        super(props);
        this.state = {
            nome : '',
            email : '',
            setor : ''
        }
    }

    buscarDadosUsuario = () => {
            console.log('Fazer chamdada para a API trazendo os dados do usuario')

            // Faz a chamada para a API
            axios('http://localhost:5000/api/Usuario') 
            
            // Define  tipo de dado do retorno da requisição (JSON)
            .then(resposta => resposta.json())

            .then(dados => this.setState({}))
    }

    // Chama a função buscarDadosUsuario() assim que o componente é renderizado
    componentDidMount(){
        this.buscarDadosUsuario();
    }

    render(){
        return(
            <body>
                <header className="Perfil-header">
                    <div className="top-left"></div>
                    <div className="top-right">
                        <h2>Perfil do Usuário</h2>
                    </div>
                </header>

                <main className="Perfil-main">
                    <div className="main-left">
                        <Link to="/login" className="botao-sair">Sair</Link>
                    </div>
                    <div className="main-right">
                        <div className="main-central">
                            <div className="main-central-picture">
                                <img src={Profile}/>
                            </div>
                            <div className="main-central-name">
                                <h1>Tadeus Araujo</h1>
                            </div>
                            <div className="main-central-info">
                                <p>Email:</p>
                                <input type="text" name="email" disabled/>     
                                <p>Setor:</p>
                                <input type="text" name="setor" disabled/>    
                            </div>
                            <div className="main-central-back">
                                <Link to="/dashboard" className="botao-voltar">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </main>
            </body>
        )
    }
}

export default Perfil;