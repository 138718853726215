import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';

import './index.css';

import App from './pages/home/App';
import Dashboard from './pages/dashboard/dashboard';
import Perfil from './pages/perfil/perfil';
import Cadastro from './pages/Cadastro/Cadastro';
import Login from './pages/login/login';

import reportWebVitals from './reportWebVitals';

const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={App}/> {/* Home */}
        <Route path="/home" component={App} /> {/* Home */}
        <Route path="/cadastro" component={Cadastro} /> {/* Cadastro */}
        <Route path="/login" component={Login} /> {/* Login */}
        <Route path="/dashboard" component={Dashboard}/> {/*Dashboard*/}
        <Route path="/perfil" component={Perfil} /> {/* Perfil */}
      </Switch>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();