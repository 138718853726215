import { Component } from "react";
import { Link } from 'react-router-dom';

import '../../assets/css/dashboard.css';

import Profile from "../../assets/img/profile.png"

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            chamado : '',
            setor : '',
            responsavel : '',
            local : '',
            prioridade : '',
            status : '',
        }
    }

    
    render(){
        return(
            <body>
                <header className="Dashboard-header">
                    <div className="topo-left"></div>
                    <div className="topo-center">
                        <h2>Lista</h2>
                    </div>
                    <div className="topo-right">
                        <button className="button-chamado">Adicionar chamado</button>
                    </div>
                </header>
                
                <main className="Dashboard-main">
                    <div className="main-esq">
                        <div className="main-esq-usuario">
                            <div className="main-esq-usuario-foto">
                                <img src={Profile}/>    
                            </div>
                            <div className="main-esq-usuario-nome">
                                <p>Bem vindo(a)</p>
                                <h3>Tadeus Araujo</h3>      
                            </div>
                        </div>

                        <div className="main-esq-usuario-perfil">
                            <Link to="/perfil" className="perfil-acess">Acessar Perfil</Link>
                        </div>

                        <div className="main-esq-setor1">
                            <h3>Setores:</h3>
                        </div>
                        <div className="main-esq-setor2">
                            <label for="a1"> Manutenção</label>
                            <input type="checkbox" id="area1" name="Man" value="Manutenção"></input>

                            <label for="a1"> Limpeza</label>
                            <input type="checkbox" id="area2" name="Lim" value="Limpeza"></input>
                            
                            <label for="a1"> Suporte</label>
                            <input type="checkbox" id="area3" name="Sup" value="Suporte"></input>
                        </div>
                        <div className="main-esq-sair">
                            <Link to="/login" className="botao-sair">Sair</Link>
                        </div>
                    </div>

                    <div className="main-dir">
                        
                    </div>
                   
                </main>
            </body>
        )
    }
}
export default Dashboard;